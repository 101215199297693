import { Row, Col } from 'react-bootstrap';
import { DataStatus, InfoItemHorizontal } from 'components';
import { InputImage } from './InputImage';

export const InfoIdentitasPribadi = ({ data }) => (
  <Row>
    <Col md="6">
      <InfoItemHorizontal
        label="Nomor Induk Kependudukan (NIK)"
        text={data.nik}
      />

      <div className="mt-2" style={{ width: 180, fontSize: 14 }}>
        Foto KTP
      </div>
      {data.foto_ktp ? (
        <InputImage readOnly imageLink={data.foto_ktp} />
      ) : (
        <DataStatus text="Tidak ada foto" />
      )}
    </Col>

    <Col md="6">
      <InfoItemHorizontal
        label="Nomor Pokok Wajib Pajak (NPWP)"
        text={data.npwp}
      />

      <div className="mt-2" style={{ width: 180, fontSize: 14 }}>
        Foto NPWP
      </div>
      {data.foto_npwp ? (
        <InputImage readOnly imageLink={data.foto_npwp} />
      ) : (
        <DataStatus text="Tidak ada foto" />
      )}
    </Col>
  </Row>
);
