import { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { Input, InputCurrency, SelectSearch } from 'components';
import { DropdownCustomerContext } from '../Context';

export const FormPerbankan = () => {
  const { values, setFieldValue } = useFormikContext();
  const { dropdownBank } = useContext(DropdownCustomerContext);

  return (
    <Row>
      <Col md="6">
        <SelectSearch
          label="Bank"
          placeholder="Pilih salah satu..."
          defaultValue={dropdownBank?.data?.find(
            ({ value }) => value === values.kode_bank
          )}
          onChange={({ value }) => setFieldValue('kode_bank', value)}
          option={dropdownBank?.data}
          loading={dropdownBank?.isLoading}
        />
        <InputCurrency
          allowLeadingZeros
          label="No. Rekening"
          placeholder="Masukkan nomor rekening"
          prefix=""
          thousandSeparator=""
          value={values.no_rekening}
          onChange={(value) => setFieldValue('no_rekening', value)}
          decimalScale={0}
        />
        <Input
          label="Atas Nama"
          placeholder="Masukkan atas nama"
          value={values.atas_nama}
          onChange={({ target }) => setFieldValue('atas_nama', target.value)}
        />
      </Col>
    </Row>
  );
};
