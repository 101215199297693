import { Services, UploadServices } from '../../../services';

class CustomerApi {
  get() {
    return Services.get('/crm/mastercustomer');
  }

  getPage(params) {
    return Services.get(`/crm/mastercustomer/page`, { params });
  }

  getSingle(params) {
    return Services.get(`/crm/mastercustomer/single`, { params });
  }

  getKode() {
    return Services.get('/crm/mastercustomer/no_baru');
  }

  getJenis() {
    return Services.get('/crm/jeniscustomer/dropdown');
  }

  getNegara() {
    return Services.get('/crm/wilayah/dropdown_internasional');
  }

  getProvinsi() {
    return Services.get('/crm/wilayah/dropdown_provinsi');
  }

  getKabupaten(id) {
    return Services.get('/crm/wilayah/dropdown_kabupaten?id_provinsi=' + id);
  }

  getKecamatan(id) {
    return Services.get('/crm/wilayah/dropdown_kecamatan?id_kabupaten=' + id);
  }

  getDesa(id) {
    return Services.get('/crm/wilayah/dropdown_desa?id_kecamatan=' + id);
  }

  getBank() {
    return Services.get('/crm/bank/dropdown');
  }
  search(key) {
    return Services.get(
      '/crm/mastercustomer/page/?per_page=10&page=1&q=' + key
    );
  }

  show(value) {
    return Services.put('/crm/mastercustomer/show', value);
  }

  hide(value) {
    return Services.put('/crm/mastercustomer/hide', value);
  }

  create(value) {
    return Services.post('/crm/mastercustomer', value);
  }

  update(value) {
    return Services.put('/crm/mastercustomer', value);
  }

  delete(value) {
    return Services.post('/crm/mastercustomer/delete', value);
  }

  uploadKTP(value) {
    return UploadServices.post('/upload/ktp', value);
  }

  uploadNPWP(value) {
    return UploadServices.post('/upload/npwp', value);
  }

  checkDuplicate(params) {
    return Services.get('/crm/mastercustomer/check_duplicate', { params });
  }
}

export default new CustomerApi();
