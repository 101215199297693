import { Col, Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { InputCurrency } from 'components';
import { InputImage } from './InputImage';

export const FormIdentitasPribadi = () => {
  const { values, errors, touched, setFieldValue } = useFormikContext();

  return (
    <Row>
      <Col md="6">
        <InputCurrency
          allowLeadingZeros
          label="Nomor Induk Kependudukan "
          placeholder="Masukkan NIK sesuai KTP"
          prefix=""
          thousandSeparator=""
          value={values.nik}
          onChange={(value) => setFieldValue('nik', value)}
          decimalScale={0}
          error={errors.nik && touched.nik}
          errorText={errors.nik}
        />

        <InputImage
          label="Upload Foto KTP"
          placeholder={values.fileKtp?.name}
          imageLink={values.fileKtp?.link}
          onChange={(imageFile) => setFieldValue('fileKtp', imageFile)}
          onDeleteImage={() =>
            setFieldValue('fileKtp', {
              name: '',
              data: null,
              link: null,
              shortLink: null,
            })
          }
        />
      </Col>

      <Col md="6">
        <InputCurrency
          allowLeadingZeros
          label="Nomor Pokok Wajib Pajak"
          placeholder="Masukkan NPWP"
          prefix=""
          thousandSeparator=""
          value={values.npwp}
          onChange={(value) => setFieldValue('npwp', value)}
          decimalScale={0}
        />

        <InputImage
          label="Upload Foto NPWP"
          placeholder={values.fileNpwp.name}
          imageLink={values.fileNpwp?.link}
          onChange={(imageFile) => setFieldValue('fileNpwp', imageFile)}
          onDeleteImage={() =>
            setFieldValue('fileNpwp', {
              name: '',
              data: null,
              link: null,
              shortLink: null,
            })
          }
        />
      </Col>
    </Row>
  );
};
