import { createContext } from 'react';
import { useQuery } from 'react-query';
import { CustomerApi } from 'api';

export const DropdownCustomerContext = createContext();

export const DropdownCustomerProvider = ({ children }) => {
  const STALE_DURATION_MINUTES = 6 * 60 * 1000; // 6 MINUTES
  const dropdownMenuKey = 'customer';

  const dropdownJenisCustomer = useQuery(
    ['dropdown', 'jenis-customer', dropdownMenuKey],
    () =>
      CustomerApi.getJenis().then((res) => {
        return (
          res.data.data.map((v) => ({
            value: v.id_jenis_customer,
            label: v.nama_jenis_customer,
          })) ?? []
        );
      }),
    { staleTime: STALE_DURATION_MINUTES }
  );

  const dropdownBank = useQuery(
    ['dropdown', 'bank', dropdownMenuKey],
    () =>
      CustomerApi.getBank().then((res) => {
        return (
          res.data.data.map((v) => ({
            value: v.kode_bank,
            label: v.nama_bank,
          })) ?? []
        );
      }),
    { staleTime: STALE_DURATION_MINUTES }
  );

  const dropdownProvinsi = useQuery(
    ['dropdown', 'provinsi', dropdownMenuKey],
    () =>
      CustomerApi.getProvinsi().then((res) => {
        return res.data.data.map((v) => ({
          value: v.id_provinsi,
          label: v.nama_provinsi,
        }));
      }),
    { staleTime: STALE_DURATION_MINUTES }
  );

  const dropdownNegara = useQuery(
    ['dropdown', 'negara', dropdownMenuKey],
    () =>
      CustomerApi.getNegara().then((res) => {
        return res.data.data.map((v) => ({
          value: v.id,
          label: v.name,
        }));
      }),
    { staleTime: STALE_DURATION_MINUTES }
  );

  const dropdownKewarganegaraan = [
    { value: 'WNI', label: 'Warga Negara Indonesia (WNI)' },
    { value: 'WNA', label: 'Warga Negara Asing (WNA)' },
  ];

  return (
    <DropdownCustomerContext.Provider
      value={{
        dropdownJenisCustomer,
        dropdownBank,
        dropdownProvinsi,
        dropdownKewarganegaraan,
        dropdownNegara,
      }}
    >
      {children}
    </DropdownCustomerContext.Provider>
  );
};

export const withDropdownCustomerProvider = (Component) => (props) => {
  return (
    <DropdownCustomerProvider>
      <Component {...props} />
    </DropdownCustomerProvider>
  );
};
