import { useContext } from 'react';
import { Formik } from 'formik';
import { FilterModal, SelectSearch } from 'components';
import { DropdownCustomerContext } from '../Context';

export const ModalFilterCustomer = ({ show, setShow, filter, setFilter }) => {
  const { dropdownKewarganegaraan, dropdownJenisCustomer } = useContext(
    DropdownCustomerContext
  );
  const defaultDropdown = { value: null, label: 'Semua' };

  const initialValues = {
    id_jenis_customer: filter?.id_jenis_customer,
    kewarganegaraan: filter?.kewarganegaraan,
  };

  const onSubmit = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setFilter((prev) => ({
        ...prev,
        ...values,
        active: true,
        page: '1',
      }));

      setShow(false);
      return;
    }

    setFilter({ ...values, filter: false });
    setShow(false);
  };

  const onResetButtonClick = () => {
    setFilter((prev) => ({
      ...prev,
      page: '1',
      active: false,
      id_jenis_customer: undefined,
      kewarganegaraan: undefined,
    }));
    setShow(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, handleSubmit, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <SelectSearch
            label="Jenis Customer"
            placeholder="Pilih salah satu..."
            loading={dropdownJenisCustomer.isLoading}
            option={[defaultDropdown].concat(dropdownJenisCustomer.data)}
            defaultValue={
              values.id_jenis_customer
                ? dropdownJenisCustomer?.data?.find(
                    (v) => values.id_jenis_customer === v.value
                  )
                : defaultDropdown
            }
            onChange={({ value }) => setFieldValue('id_jenis_customer', value)}
          />

          <SelectSearch
            label="Kewarganegaraan"
            placeholder="Pilih salah satu..."
            option={[defaultDropdown].concat(dropdownKewarganegaraan)}
            defaultValue={
              values.kewarganegaraan
                ? dropdownKewarganegaraan?.find(
                    (v) => values.kewarganegaraan === v.value
                  )
                : defaultDropdown
            }
            onChange={({ value }) => setFieldValue('kewarganegaraan', value)}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
