import { Modal } from 'react-bootstrap';
import { ActionButton } from 'components';

export const ModalDeleteCustomer = ({
  show,
  onHide,
  onSubmit,
  data,
  loading,
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <span className="text-danger">Hapus Data Customer</span>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h5>
          <span>Hapus data dengan nama: </span>
          <br />
          <b>{data?.nama_customer}</b>
        </h5>
        <small className="text-danger">
          Data yang dihapus tidak dapat dikembalikan!
        </small>
        <div className="d-flex justify-content-center mt-3">
          <ActionButton
            variant="outline-secondary"
            className="m-1"
            text="Batal"
            onClick={onHide}
          />
          <ActionButton
            variant="danger"
            className="m-1"
            text="Hapus Data"
            loading={loading}
            onClick={onSubmit}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
