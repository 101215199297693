import { Row, Col } from 'react-bootstrap';
import { InfoItemHorizontal } from 'components';

export const InfoDukcapil = ({ data }) => (
  <Row>
    <Col md="6">
      <InfoItemHorizontal label="Provinsi" text={data.nama_provinsi} />
      <InfoItemHorizontal label="Kabupaten/Kota" text={data.nama_kabupaten} />
      <InfoItemHorizontal label="Kecamatan" text={data.nama_kecamatan} />
      <InfoItemHorizontal label="Desa" text={data.nama_desa} />
      <InfoItemHorizontal label="Kode Pos" text={data.kode_pos} />
    </Col>
  </Row>
);
