import { Fragment, useEffect, useState } from 'react';
import { Col, Row, Nav, Tab, Card } from 'react-bootstrap';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { ActionButton, Alert, BackButton } from 'components';
import {
  FormCustomer,
  FormDukcapil,
  FormPerbankan,
  FormIdentitasPribadi,
  NavTab,
  TabPane,
} from './Components';
import { withDropdownCustomerProvider } from './Context';
import {
  formInitialValues,
  validationSchema,
  uploadKTP,
  uploadNPWP,
} from './Utils';
import { CustomerApi } from 'api';

const TambahCustomer = ({ setNavbarTitle }) => {
  const history = useHistory();
  const [alert, setAlert] = useState({
    text: '',
    variant: '',
  });

  const onSubmitForm = async (values) => {
    try {
      const foto_ktp = await uploadKTP(values?.fileKtp);
      const foto_npwp = await uploadNPWP(values?.fileNpwp);

      await CustomerApi.create({
        ...values,
        foto_ktp,
        foto_npwp,
      })
        .then(() => history.push('/crm/master/customer'))
        .catch(() =>
          setAlert({
            text: 'Gagal tambah data customer',
            variant: 'danger',
          })
        );
    } catch (err) {
      setAlert({
        text: `Gagal tambah data customer! ${err}`,
        variant: 'danger',
      });
    }
  };

  useEffect(() => {
    setNavbarTitle('Customer');
  }, []);

  return (
    <Fragment>
      <Alert
        showCloseButton
        show={!!alert.text.length}
        variant={alert.variant}
        text={alert.text}
        onClose={() => setAlert({ text: '', variant: '' })}
      />

      <Row className="d-flex justify-content-between align-items-end mb-1">
        <Col>
          <div style={{ fontSize: '.9rem' }}>Tambah Data Customer </div>
        </Col>
        <Col className="d-flex justify-content-end">
          <BackButton onClick={() => history.goBack()} />
        </Col>
      </Row>

      <Formik
        enableReinitialize
        initialValues={formInitialValues({})}
        validationSchema={validationSchema}
        onSubmit={onSubmitForm}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Fragment>
            <Row>
              <Col>
                <FormCustomer />
              </Col>
            </Row>

            <div className="mt-4 mb-1" style={{ fontSize: '.9rem' }}>
              Informasi Lainnya (Opsional)
            </div>

            <Card>
              <Tab.Container defaultActiveKey="identitas">
                {/* Tab Navigation */}
                <Card.Header>
                  <Nav variant="tabs" defaultActiveKey="identitas">
                    <NavTab eventKey="identitas" title="Identitas Pribadi" />
                    <NavTab eventKey="dukcapil" title="Data Dukcapil" />
                    <NavTab eventKey="perbankan" title="Data Perbankan" />
                  </Nav>
                </Card.Header>

                {/* Tab Content */}
                <Card.Body>
                  <Tab.Content>
                    <TabPane
                      eventKey="identitas"
                      component={<FormIdentitasPribadi />}
                    />
                    <TabPane eventKey="dukcapil" component={<FormDukcapil />} />
                    <TabPane
                      eventKey="perbankan"
                      component={<FormPerbankan />}
                    />
                  </Tab.Content>
                </Card.Body>
              </Tab.Container>
            </Card>

            <Row className="mt-3">
              <Col className="d-flex justify-content-end ">
                <ActionButton
                  className="px-4"
                  text="Simpan"
                  loading={isSubmitting}
                  onClick={handleSubmit}
                />
              </Col>
            </Row>
          </Fragment>
        )}
      </Formik>
    </Fragment>
  );
};

export default withDropdownCustomerProvider(TambahCustomer);
