import { useContext, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { Input, InputCurrency, SelectSearch, TextArea } from 'components';
import { DropdownCustomerContext } from '../Context';
import { CustomerApi } from 'api';

export const FormCustomer = ({ type = 'create' }) => {
  const { values, errors, touched, setFieldValue } = useFormikContext();
  const { dropdownJenisCustomer, dropdownKewarganegaraan, dropdownNegara } =
    useContext(DropdownCustomerContext);

  const isIndonesian = values.kewarganegaraan === 'WNI';

  const filteredDropdownNegara = isIndonesian
    ? dropdownNegara?.data
    : dropdownNegara?.data?.filter(({ label }) => label !== 'INDONESIA');

  useEffect(() => {
    if (type === 'create') {
      CustomerApi.getKode()
        .then((res) => setFieldValue('kode_customer', res.data.data))
        .catch(() => window.alert('Gagal memuat Kode Customer'));
    }
  }, []);

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md="6">
            <Row>
              <Col>
                <Input
                  readOnly
                  label="Kode Customer*"
                  value={values.kode_customer}
                />
              </Col>
              <Col>
                <SelectSearch
                  label="Jenis Customer*"
                  placeholder="Pilih salah satu..."
                  loading={dropdownJenisCustomer?.isLoading}
                  option={dropdownJenisCustomer?.data}
                  defaultValue={dropdownJenisCustomer?.data?.find(
                    ({ value }) => value === values.id_jenis_customer
                  )}
                  onChange={({ value }) =>
                    setFieldValue('id_jenis_customer', value)
                  }
                  error={errors.id_jenis_customer && touched.id_jenis_customer}
                  errorText={errors.id_jenis_customer}
                />
              </Col>
            </Row>
            <Input
              label="Nama Customer*"
              value={values.nama_customer}
              onChange={({ target }) =>
                setFieldValue('nama_customer', target.value)
              }
              error={errors.nama_customer && touched.nama_customer}
              errorText={errors.nama_customer}
            />
            <Row>
              <Col>
                <SelectSearch
                  label="Kewarganegaraan*"
                  placeholder="Pilih salah satu..."
                  option={dropdownKewarganegaraan}
                  defaultValue={dropdownKewarganegaraan?.find(
                    ({ value }) => value === values.kewarganegaraan
                  )}
                  onChange={({ value }) => {
                    setFieldValue('kewarganegaraan', value);

                    if (value === 'WNI') {
                      setFieldValue('id_negara', '100');
                      return;
                    }

                    setFieldValue('id_negara', '');
                  }}
                  error={errors.kewarganegaraan && touched.kewarganegaraan}
                  errorText={errors.kewarganegaraan}
                />
              </Col>
              <Col>
                <SelectSearch
                  isDisabled={isIndonesian}
                  key={isIndonesian}
                  label="Asal Negara*"
                  placeholder="Pilih salah satu..."
                  loading={dropdownNegara?.isLoading}
                  option={filteredDropdownNegara}
                  defaultValue={
                    dropdownNegara?.data?.find(
                      ({ value }) => value === values.id_negara
                    ) ?? null
                  }
                  onChange={({ value }) => setFieldValue('id_negara', value)}
                  error={errors.id_negara && touched.id_negara}
                  errorText={errors.id_negara}
                />
              </Col>
            </Row>
            <TextArea
              label="Alamat Customer*"
              value={values.alamat}
              onChange={({ target }) => setFieldValue('alamat', target.value)}
              error={errors.alamat && touched.alamat}
              errorText={errors.alamat}
            />
          </Col>

          <Col md="6">
            <Row>
              <Col>
                <InputCurrency
                  label="Telepon*"
                  prefix=""
                  format="+##############"
                  thousandSeparator=""
                  value={values.telepon}
                  onChange={(value) => {
                    setFieldValue('telepon', value?.toString());
                  }}
                  decimalScale={0}
                  error={errors.telepon && touched.telepon}
                  errorText={errors.telepon}
                />
              </Col>
              <Col>
                <Input
                  label="Email"
                  value={values.email}
                  onChange={({ target }) =>
                    setFieldValue('email', target.value)
                  }
                />
              </Col>
            </Row>
            <TextArea
              label="Sosial Media"
              rows={2}
              value={values.media_sosial}
              onChange={({ target }) =>
                setFieldValue('media_sosial', target.value)
              }
            />
            <TextArea
              label="Keterangan"
              rows={4}
              value={values.keterangan}
              onChange={({ target }) =>
                setFieldValue('keterangan', target.value)
              }
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
