import { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useFormikContext } from 'formik';
import { SelectSearch, InputCurrency } from 'components';
import { CustomerApi } from 'api';
import { DropdownCustomerContext } from '../Context';

export const FormDukcapil = () => {
  const { values, setValues, errors, touched } = useFormikContext();
  const { dropdownProvinsi } = useContext(DropdownCustomerContext);

  const dropdownKabupaten = useQuery(
    ['dropdown', 'kabupaten', 'customer', values.id_provinsi],
    () =>
      CustomerApi.getKabupaten(values.id_provinsi).then((res) =>
        res.data.data.map((v) => ({
          value: v.id_kabupaten,
          label: v.nama_kabupaten,
        }))
      ),
    { enabled: !!values.id_provinsi }
  );

  const dropdownKecamatan = useQuery(
    ['dropdown', 'kecamatan', 'customer', values.id_kabupaten],
    () =>
      CustomerApi.getKecamatan(values.id_kabupaten).then((res) =>
        res.data.data.map((v) => ({
          value: v.id_kecamatan,
          label: v.nama_kecamatan,
        }))
      ),
    { enabled: !!values.id_kabupaten }
  );

  const dropdownDesa = useQuery(
    ['dropdown', 'desa', 'customer', values.id_kecamatan],
    () =>
      CustomerApi.getDesa(values.id_kecamatan).then((res) =>
        res.data.data.map((v) => ({
          value: v.id_desa,
          label: v.nama_desa,
        }))
      ),
    { enabled: !!values.id_kecamatan }
  );

  return (
    <Row>
      <Col md="6">
        <SelectSearch
          label="Provinsi"
          placeholder="Pilih salah satu..."
          option={dropdownProvinsi?.data}
          loading={dropdownProvinsi?.isLoading}
          onChange={({ value }) =>
            setValues((prev) => ({
              ...prev,
              id_provinsi: value,
              id_kabupaten: '',
              id_kecamatan: '',
              id_desa: '',
            }))
          }
          defaultValue={dropdownProvinsi?.data?.find(
            ({ value }) => value === values.id_provinsi
          )}
          error={errors.id_provinsi && touched.id_provinsi}
          errortext={errors.id_provinsi}
        />
        <SelectSearch
          isDisabled={!values?.id_provinsi}
          label="Kabupaten/Kota"
          placeholder="Pilih salah satu..."
          option={dropdownKabupaten?.data}
          loading={dropdownKabupaten?.isLoading}
          onChange={({ value }) =>
            setValues((prev) => ({
              ...prev,
              id_kabupaten: value,
              id_kecamatan: '',
              id_desa: '',
            }))
          }
          defaultValue={dropdownKabupaten?.data?.find(
            ({ value }) => value === values.id_kabupaten
          )}
          error={errors.id_kabupaten && touched.id_kabupaten}
          errortext={errors.id_kabupaten}
        />
        <SelectSearch
          isDisabled={!values?.id_kabupaten}
          label="Kecamatan"
          placeholder="Pilih salah satu..."
          option={dropdownKecamatan?.data}
          loading={dropdownKecamatan?.isLoading}
          onChange={({ value }) =>
            setValues((prev) => ({
              ...prev,
              id_kecamatan: value,
              id_desa: '',
            }))
          }
          defaultValue={dropdownKecamatan?.data?.find(
            ({ value }) => value === values.id_kecamatan
          )}
          error={errors.id_kecamatan && touched.id_kecamatan}
          errortext={errors.id_kecamatan}
        />
        <SelectSearch
          isDisabled={!values?.id_kecamatan}
          label="Desa/Kelurahan"
          placeholder="Pilih salah satu..."
          option={dropdownDesa?.data}
          loading={dropdownDesa?.isLoading}
          onChange={({ value }) =>
            setValues((prev) => ({
              ...prev,
              id_desa: value,
            }))
          }
          defaultValue={dropdownDesa?.data?.find(
            ({ value }) => value === values.id_desa
          )}
          error={errors.id_desa && touched.id_desa}
          errortext={errors.id_desa}
        />
        <InputCurrency
          allowLeadingZeros
          label="Kode Pos"
          placeholder="Masukkan Kode Pos"
          prefix=""
          thousandSeparator=""
          value={values.kode_pos}
          onChange={(value) =>
            setValues((prev) => ({
              ...prev,
              kode_pos: value,
            }))
          }
          decimalScale={0}
        />
      </Col>
    </Row>
  );
};
