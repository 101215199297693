const generateShortLink = (url) => url?.split('/').slice(3).join('/') ?? '';

export const formInitialValues = (data) => ({
  id_customer: data?.id_customer ?? undefined,
  kode_customer: data?.kode_customer ?? '',
  nama_customer: data?.nama_customer ?? '',
  id_jenis_customer: data?.id_jenis_customer ?? '',
  keterangan: data?.keterangan ?? '',
  telepon: data?.telepon ?? '',
  email: data?.email ?? '',
  media_sosial: data?.media_sosial ?? '',
  alamat: data?.alamat ?? '',
  id_provinsi: data?.id_provinsi ?? undefined,
  id_kabupaten: data?.id_kabupaten ?? undefined,
  id_kecamatan: data?.id_kecamatan ?? undefined,
  id_desa: data?.id_desa ?? undefined,
  kode_pos: data?.kode_pos ?? '',
  kode_bank: data?.kode_bank ?? undefined,
  atas_nama: data?.atas_nama ?? '',
  no_rekening: data?.no_rekening ?? '',
  nik: data?.nik ?? '',
  npwp: data?.npwp ?? '',
  kewarganegaraan: data?.kewarganegaraan ?? '',
  id_negara: data?.id_negara ?? '',
  fileKtp: {
    name: '',
    data: '',
    link: data?.foto_ktp,
    shortLink: generateShortLink(data?.foto_ktp),
  },
  fileNpwp: {
    name: '',
    data: '',
    link: data?.foto_npwp,
    shortLink: generateShortLink(data?.foto_npwp),
  },
});
