import React, { useState, useEffect } from 'react';
import { Modal, Col, Row, ButtonGroup } from 'react-bootstrap';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { isArray } from 'lodash';
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  UpdateButton,
  ActionButton,
  DataStatus,
  CreateModal,
  UpdateModal,
  Alert,
  Input,
  TextArea,
  Pagination,
  Switch,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  DeleteButton,
  InfoItemVertical,
  ReadModal,
} from 'components';
import { JenisCustomerApi } from 'api';
import { PageNumber } from 'utilities';
import { useIsGuest } from 'hooks';
import { IoEyeOutline } from 'react-icons/io5';

const JenisCustomer = ({ setNavbarTitle }) => {
  const isGuest = useIsGuest();
  const [searchKey, setSearchKey] = useState('');
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState({
    loading: false,
    error: false,
  });
  const [alert, setAlert] = useState({
    show: false,
    variant: '',
    text: '',
  });
  const [modalAlert, setModalAlert] = useState({
    show: false,
    variant: '',
    text: '',
  });
  const [modal, setModal] = useState({
    show: true,
    action: '',
    data: {},
    loading: false,
  });
  const [pagination, setPagination] = useState({
    page: 1,
    dataLength: 10,
    totalPage: 1,
    dataCount: 0,
  });

  const formInitialValues = {
    id_jenis_customer: modal?.data?.id_jenis_customer,
    kode_jenis_customer: modal?.data?.kode_jenis_customer,
    nama_jenis_customer: modal?.data?.nama_jenis_customer,
    keterangan: modal?.data?.keterangan,
  };

  const formValidationSchema = Yup.object().shape({
    kode_jenis_customer: Yup.string().required(
      'Kode jenis customer wajib diisi'
    ),
    nama_jenis_customer: Yup.string().required(
      'Nama jenis customer wajib diisi'
    ),
  });

  const getData = () => {
    setFetching({
      loading: true,
      error: false,
    });

    JenisCustomerApi.getPage({
      per_page: pagination.dataLength,
      page: pagination.page,
      q: searchKey,
    })
      .then((res) => {
        setData(res?.data?.data);
        setPagination({
          ...pagination,
          totalPage: res?.data?.total_page,
          dataCount: res?.data?.data_count,
        });
        setFetching({
          loading: false,
          error: false,
        });
      })
      .catch((err) => {
        setAlert({
          show: true,
          variant: 'danger',
          text: err?.response?.data?.message ?? 'Data gagal dimuat',
        });
        setFetching({
          loading: false,
          error: true,
        });
      });
  };

  const changeDataStatus = (status, id) => {
    setFetching({ loading: true, error: false });

    const value = { id_jenis_customer: id };

    const onLoadedSuccess = () => {
      setAlert({
        show: true,
        variant: 'primary',
        text: 'Ubah status data berhasil',
      });
    };

    const onLoadedFailed = () => {
      setAlert({
        show: true,
        variant: 'danger',
        text: 'Ubah status data gagal',
      });
    };

    status === true
      ? JenisCustomerApi.show(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData())
      : JenisCustomerApi.hide(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData());
  };

  const createJenisCustomer = async (values, { resetForm }) => {
    let npwp = '';
    let ktp = '';

    // Check jika memiliki file npwp yang harus disimpan
    if (values?.fileNpwp?.data) {
      let formDatafileNpwp = new FormData();

      formDatafileNpwp.append('file', values?.fileNpwp?.data);

      await JenisCustomerApi.uploadNPWP(formDatafileNpwp)
        .then((res) => {
          npwp = res?.data?.data;
        })
        .catch((err) => {
          npwp = '';
          setModalAlert({
            section: 'alamat',
            show: true,
            variant: 'danger',
            text: isArray(err?.response?.data?.error)
              ? err?.response?.data?.error?.map((val) => <li>{val}</li>)
              : err?.response?.data?.message,
          });
        });
    }

    // Check jika memiliki file ktp yang harus disimpan
    if (values?.fileKtp?.data) {
      let formDatafileKtp = new FormData();

      formDatafileKtp.append('file', values?.fileKtp?.data);

      await JenisCustomerApi.uploadKTP(formDatafileKtp)
        .then((res) => {
          ktp = res?.data?.data;
        })
        .catch((err) => {
          ktp = '';
          setModalAlert({
            section: 'alamat',
            show: true,
            variant: 'danger',
            text: isArray(err?.response?.data?.error)
              ? err?.response?.data?.error?.map((val) => <li>{val}</li>)
              : err?.response?.data?.message,
          });
        });
    }

    return await JenisCustomerApi.create({
      ...values,
      foto_ktp: ktp,
      foto_npwp: npwp,
    })
      .then(() => {
        setModal({
          show: false,
          action: '',
          data: {},
        });
        setAlert({
          show: true,
          text: 'Data berhasil disimpan',
          variant: 'primary',
        });
        getData();
      })
      .catch((err) => {
        setModalAlert({
          section: 'alamat',
          show: true,
          variant: 'danger',
          text: isArray(err?.response?.data?.error)
            ? err?.response?.data?.error?.map((val) => <li>{val}</li>)
            : err?.response?.data?.message,
        });
        resetForm();
      });
  };

  const updateJenisCustomer = async (values, { resetForm }) => {
    let npwp = values?.fileNpwp?.linkShort;
    let ktp = values?.fileKtp?.linkShort;

    // Check jika memiliki file npwp yang harus disimpan
    if (values?.fileNpwp?.data) {
      let formDatafileNpwp = new FormData();

      formDatafileNpwp.append('file', values?.fileNpwp?.data);

      await JenisCustomerApi.uploadNPWP(formDatafileNpwp)
        .then((res) => {
          npwp = res?.data?.data;
        })
        .catch((err) => {
          npwp = '';
          setModalAlert({
            section: 'alamat',
            show: true,
            variant: 'danger',
            text: isArray(err?.response?.data?.error)
              ? err?.response?.data?.error?.map((val) => <li>{val}</li>)
              : err?.response?.data?.message,
          });
        });
    }

    // Check jika memiliki file ktp yang harus disimpan
    if (values?.fileKtp?.data) {
      let formDatafileKtp = new FormData();

      formDatafileKtp.append('file', values?.fileKtp?.data);

      await JenisCustomerApi.uploadKTP(formDatafileKtp)
        .then((res) => {
          ktp = res?.data?.data;
        })
        .catch((err) => {
          ktp = '';
          setModalAlert({
            section: 'alamat',
            show: true,
            variant: 'danger',
            text: isArray(err?.response?.data?.error)
              ? err?.response?.data?.error?.map((val) => <li>{val}</li>)
              : err?.response?.data?.message,
          });
        });
    }

    return await JenisCustomerApi.update({
      ...values,
      foto_ktp: ktp,
      foto_npwp: npwp,
    })
      .then(() => {
        setModal({
          show: false,
          action: '',
          data: {},
        });
        setAlert({
          show: true,
          text: 'Data berhasil diubah',
          variant: 'primary',
        });
        getData();
      })
      .catch((err) => {
        setModalAlert({
          section: 'alamat',
          show: true,
          variant: 'danger',
          text: isArray(err?.response?.data?.error)
            ? err?.response?.data?.error?.map((val) => <li>{val}</li>)
            : err?.response?.data?.message,
        });
        resetForm();
      });
  };

  const deleteJenisCustomer = () => {
    setModal({
      ...modal,
      loading: true,
    });

    JenisCustomerApi.delete({
      id_jenis_customer: modal?.data?.id_jenis_customer,
    })
      .then(() => {
        setAlert({
          show: true,
          variant: 'primary',
          text: 'Data berhasil dihapus!',
        });
      })
      .catch((err) =>
        setAlert({
          show: true,
          variant: 'danger',
          text: isArray(err?.response?.data?.error)
            ? err?.response?.data?.error?.map((val) => <li>{val}</li>)
            : err?.response?.data?.message,
        })
      )
      .finally(() => {
        getData();
        setModal({
          show: false,
          action: '',
          data: {},
          loading: false,
        });
      });
  };

  useEffect(() => {
    getData();
    setNavbarTitle('Jenis Customer');
  }, [pagination.dataLength, pagination.page, searchKey]);

  return (
    <>
      <CRUDLayout>
        <CRUDLayout.Head>
          <CRUDLayout.HeadSearchSection>
            <Row>
              <Col md="8">
                <InputSearch
                  onChange={(e) => {
                    setTimeout(() => {
                      setSearchKey(e.target.value);
                      setPagination({
                        ...pagination,
                        page: 1,
                      });
                    }, 1000);
                  }}
                  onSubmit={(e) => e.preventDefault()}
                />
              </Col>
            </Row>
          </CRUDLayout.HeadSearchSection>

          <CRUDLayout.HeadButtonSection>
            {/* <ExportButton /> */}
            <CreateButton
              onClick={() => {
                setModal({ show: true, action: 'create', data: {} });
                setAlert({ show: false, text: '', variant: '' });
              }}
            />
          </CRUDLayout.HeadButtonSection>
        </CRUDLayout.Head>

        <div className="mt-2" />

        {/* ALert */}
        <Alert
          show={alert.show}
          showCloseButton={true}
          variant={alert.variant}
          text={alert.text}
          onClose={() => setAlert({ show: false, variant: '', text: '' })}
        />
        {console.log(fetching)}
        {/* Table Section */}
        {fetching.loading || fetching.error ? (
          <DataStatus
            loading={fetching.loading}
            text={fetching.loading ? 'Memuat data...' : 'Data gagal dimuat'}
          />
        ) : data ? (
          data.length > 0 ? (
            <>
              <CRUDLayout.Table>
                <THead>
                  <Tr>
                    <ThFixed>No</ThFixed>
                    {!isGuest && <ThFixed>Aksi</ThFixed>}
                    <ThFixed>Kode</ThFixed>
                    <Th>Nama Jenis Customer</Th>
                    <Th>Keterangan</Th>
                  </Tr>
                </THead>
                <TBody>
                  {data.map((val, index) => (
                    <Tr key={index}>
                      <TdFixed>
                        {PageNumber(
                          pagination.page,
                          pagination.dataLength,
                          index
                        )}
                      </TdFixed>
                      {!isGuest && (
                        <TdFixed>
                          <div className="d-flex justify-content-center">
                            <ButtonGroup>
                              <ActionButton
                                size="sm"
                                className="m-0"
                                text={<IoEyeOutline />}
                                onClick={() => {
                                  setAlert({
                                    show: false,
                                    text: '',
                                    variant: '',
                                  });
                                  setModal({
                                    show: true,
                                    action: 'read',
                                    data: val,
                                  });
                                }}
                              />

                              <UpdateButton
                                className="m-0"
                                onClick={() => {
                                  setAlert({
                                    show: false,
                                    text: '',
                                    variant: '',
                                  });
                                  setModal({
                                    show: true,
                                    action: 'update',
                                    data: val,
                                  });
                                }}
                              />

                              <DeleteButton
                                className="m-0"
                                onClick={() => {
                                  setAlert({
                                    show: false,
                                    text: '',
                                    variant: '',
                                  });
                                  setModal({
                                    show: true,
                                    action: 'delete',
                                    data: val,
                                  });
                                }}
                              />
                            </ButtonGroup>

                            <Switch
                              id={toString(index + 1)}
                              checked={val.is_hidden === false ? true : false}
                              onChange={() =>
                                changeDataStatus(
                                  val.is_hidden,
                                  val.id_jenis_customer
                                )
                              }
                            />
                          </div>
                        </TdFixed>
                      )}
                      <TdFixed>{val.kode_jenis_customer}</TdFixed>
                      <Td>{val.nama_jenis_customer}</Td>
                      <Td>{val.keterangan}</Td>
                    </Tr>
                  ))}
                </TBody>
              </CRUDLayout.Table>
              <Pagination
                dataLength={pagination.dataLength}
                dataNumber={
                  pagination.page * pagination.dataLength -
                  pagination.dataLength +
                  1
                }
                dataPage={pagination.page * pagination.dataLength}
                dataCount={pagination.dataCount}
                onDataLengthChange={(e) => {
                  setPagination({
                    ...pagination,
                    page: 1,
                    dataLength: e.target.value,
                  });
                }}
                currentPage={pagination.page}
                totalPage={pagination.totalPage}
                onPaginationChange={({ selected }) =>
                  setPagination({ ...pagination, page: selected + 1 })
                }
              />
            </>
          ) : (
            <DataStatus text="Tidak ada data" />
          )
        ) : (
          <DataStatus text="Server error" />
        )}
      </CRUDLayout>

      {/* Tambah Modal */}
      <CreateModal
        show={modal.show && modal.action === 'create'}
        onHide={() => {
          setModal({ show: false, action: '', data: {} });
          setModalAlert({ show: false, text: '', variant: '' });
        }}
      >
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={
            modal.action === 'create'
              ? createJenisCustomer
              : updateJenisCustomer
          }
        >
          {({ handleSubmit, isSubmitting }) => (
            <>
              <Modal.Body>
                {modalAlert.show && (
                  <Alert
                    show={modalAlert.show}
                    showCloseButton={true}
                    variant={modalAlert.variant}
                    text={modalAlert.text}
                    onClose={() =>
                      setModalAlert({ show: false, variant: '', text: '' })
                    }
                  />
                )}
                <ModalBodyForm action="create" />
              </Modal.Body>
              <Modal.Footer>
                <ActionButton
                  loading={isSubmitting}
                  variant="primary"
                  text="Simpan Data"
                  onClick={handleSubmit}
                />
              </Modal.Footer>
            </>
          )}
        </Formik>
      </CreateModal>

      {/* Ubah Modal */}
      <UpdateModal
        show={modal.show && modal.action === 'update'}
        onHide={() => {
          setModal({ show: false, action: '', data: {} });
          setModalAlert({ show: false, text: '', variant: '' });
        }}
      >
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={
            modal.action === 'create'
              ? createJenisCustomer
              : updateJenisCustomer
          }
        >
          {({ handleSubmit, isSubmitting }) => (
            <>
              <Modal.Body>
                {modalAlert.show && (
                  <Alert
                    show={modalAlert.show}
                    showCloseButton={true}
                    variant={modalAlert.variant}
                    text={modalAlert.text}
                    onClose={() =>
                      setModalAlert({ show: false, variant: '', text: '' })
                    }
                  />
                )}
                <ModalBodyForm action="update" />
              </Modal.Body>
              <Modal.Footer>
                <ActionButton
                  loading={isSubmitting}
                  variant="success"
                  text="Ubah Data"
                  onClick={handleSubmit}
                />
              </Modal.Footer>
            </>
          )}
        </Formik>
      </UpdateModal>

      {/* Detail Modal */}
      <ReadModal
        show={modal.show && modal.action === 'read'}
        onHide={() => {
          setModal({ show: false, action: '', data: {} });
          setModalAlert({ show: false, text: '', variant: '' });
        }}
      >
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={
            modal.action === 'create'
              ? createJenisCustomer
              : updateJenisCustomer
          }
        >
          {() => (
            <>
              <Modal.Body>
                {modalAlert.show && (
                  <Alert
                    show={modalAlert.show}
                    showCloseButton={true}
                    variant={modalAlert.variant}
                    text={modalAlert.text}
                    onClose={() =>
                      setModalAlert({ show: false, variant: '', text: '' })
                    }
                  />
                )}
                <ModalBodyDetail />
              </Modal.Body>
            </>
          )}
        </Formik>
      </ReadModal>

      {/* Delete Modal */}
      <ModalDelete
        show={modal.show && modal.action === 'delete'}
        loading={modal.loading}
        data={modal.data}
        onHide={() => setModal({ data: {}, show: false, loading: false })}
        onSubmit={deleteJenisCustomer}
      />
    </>
  );
};

const ModalBodyDetail = () => {
  const { values } = useFormikContext();

  return (
    <>
      <InfoItemVertical
        label="Kode Jenis Customer"
        text={values.kode_jenis_customer}
      />
      <InfoItemVertical
        label="Nama Jenis Customer"
        text={values.nama_jenis_customer}
      />
      <InfoItemVertical label="Keterangan" text={values.keterangan} />
    </>
  );
};

const ModalBodyForm = ({ action }) => {
  const { values, touched, errors, setFieldValue, handleChange, setValues } =
    useFormikContext();

  const getKode = () => {
    JenisCustomerApi.getKode()
      .then((res) => {
        setFieldValue('kode_jenis_customer', res?.data?.data);
      })
      .catch((err) => {
        setFieldValue('kode_jenis_customer', '');
      });
  };

  useEffect(() => {}, []);

  useEffect(() => {
    action === 'create' && getKode();
  }, [action]);

  return (
    <>
      <Row>
        <Col lg>
          <Input
            disabled
            label="Kode"
            type="text"
            name="kode_jenis_customer"
            placeholder="Masukan kode"
            value={values.kode_jenis_customer}
            readOnly={true}
            onChange={handleChange}
            error={
              errors.kode_jenis_customer && touched.kode_jenis_customer && true
            }
            errorText={errors.kode_jenis_customer}
          />
          <Input
            label="Nama Jenis Customer"
            type="text"
            name="nama_jenis_customer"
            placeholder="Masukan nama customer"
            value={values.nama_jenis_customer}
            onChange={handleChange}
            error={
              errors.nama_jenis_customer && touched.nama_jenis_customer && true
            }
            errorText={errors.nama_jenis_customer}
          />
          <TextArea
            label="Keterangan"
            type="text"
            name="keterangan"
            placeholder="Masukan keterangan"
            value={values.keterangan}
            onChange={handleChange}
            rows={2}
          />
        </Col>
      </Row>
    </>
  );
};

const ModalDelete = ({ show, onHide, onSubmit, data, loading }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <span className="text-danger">Hapus Data Jenis Customer</span>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h5>
          <span>Hapus data dengan nama: </span>
          <br />
          <b>{data?.nama_jenis_customer}</b>
        </h5>
        <small className="text-danger">
          Data yang dihapus tidak dapat dikembalikan!
        </small>
        <div className="d-flex justify-content-center mt-3">
          <ActionButton
            variant="outline-secondary"
            className="m-1"
            text="Batal"
            onClick={onHide}
          />
          <ActionButton
            variant="danger"
            className="m-1"
            text="Hapus Data"
            loading={loading}
            onClick={onSubmit}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default JenisCustomer;
