import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ButtonGroup, Col, Row } from 'react-bootstrap';
import { IoEyeOutline } from 'react-icons/io5';
import { debounce } from 'lodash';
import {
  ActionButton,
  Alert,
  CreateButton,
  CRUDLayout,
  DataStatus,
  DeleteButton,
  InputSearch,
  Pagination,
  Switch,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
  UpdateButton,
  FilterButton,
} from 'components';
import { useIsGuest, useSessionStorage } from 'hooks';
import { PageNumber } from 'utilities';
import { CustomerApi } from 'api';
import { withDropdownCustomerProvider } from './Context';
import { ModalFilterCustomer, ModalDeleteCustomer } from './Components';

const ListCustomer = ({ setNavbarTitle }) => {
  const isGuest = useIsGuest();
  const history = useHistory();
  const location = useLocation();
  const paginationKey = `${location.pathname}_pagination`;

  const [data, setData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [fetching, setFetching] = useState({
    loading: false,
    error: false,
  });
  const [pagination, setPagination] = useSessionStorage(paginationKey, {
    q: '',
    page: '1',
    per_page: '10',
    total_page: '1',
    data_count: '0',

    active: false,
    id_jenis_customer: undefined,
    kewarganegaraan: undefined,
  });

  const [alert, setAlert] = useState({
    show: false,
    variant: '',
    text: '',
  });

  const [modalDelete, setModalDelete] = useState({
    show: false,
    data: {},
    loading: false,
  });

  const getInitalData = () => {
    setFetching({
      loading: true,
      error: false,
    });

    CustomerApi.getPage({
      per_page: pagination.per_page,
      page: pagination.page,
      q: pagination.q,
      id_jenis_customer: pagination.id_jenis_customer,
      kewarganegaraan: pagination.kewarganegaraan,
    })
      .then((res) => {
        setData(res?.data?.data);
        setPagination({
          ...pagination,
          total_page: res?.data?.total_page,
          data_count: res?.data?.data_count,
        });

        setFetching({
          loading: false,
          error: false,
        });
      })
      .catch((err) => {
        setAlert({
          show: true,
          variant: 'danger',
          text: err?.response?.data?.message ?? 'Data gagal dimuat',
        });

        setFetching({
          loading: false,
          error: true,
        });
      });
  };

  const searchHandler = (e) => {
    const searchKey = e.target.value;

    setPagination({
      ...pagination,
      q: searchKey,
      page: '1',
    });
  };

  const changeDataStatus = (status, id_customer, index) => {
    const onLoadedSuccess = () => {
      const copyData = [...data];
      const updatedItem = copyData[index];
      updatedItem.is_hidden = !updatedItem.is_hidden;

      setData(copyData);
      setAlert({
        show: true,
        variant: 'primary',
        text: 'Ubah status data berhasil',
      });
    };

    const onLoadedFailed = () => {
      setAlert({
        show: true,
        variant: 'danger',
        text: 'Ubah status data gagal',
      });
    };

    status === true
      ? CustomerApi.show({ id_customer })
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
      : CustomerApi.hide({ id_customer })
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed());
  };

  const deleteCustomer = () => {
    setModalDelete({
      ...modalDelete,
      loading: true,
    });

    CustomerApi.delete({ id_customer: modalDelete?.data?.id_customer })
      .then(() => {
        setAlert({
          show: true,
          variant: 'primary',
          text: 'Data berhasil dihapus!',
        });
      })
      .catch((err) =>
        setAlert({
          show: true,
          variant: 'danger',
          text: err?.response?.data?.message,
        })
      )
      .finally(() => {
        getInitalData();
        setModalDelete({
          show: false,
          action: '',
          data: {},
          loading: false,
        });
      });
  };

  useEffect(() => {
    getInitalData();
    setNavbarTitle('Customer');
  }, [
    pagination.per_page,
    pagination.page,
    pagination.q,
    pagination.id_jenis_customer,
    pagination.kewarganegaraan,
  ]);

  return (
    <>
      <CRUDLayout>
        <CRUDLayout.Head>
          <CRUDLayout.HeadSearchSection>
            <Row className="mb-1">
              <Col className="d-flex flex-row justify-content-start align-items-center">
                <InputSearch
                  defaultValue={pagination.q}
                  onChange={debounce(searchHandler, 500)}
                />
                <FilterButton
                  active={pagination.active}
                  onClick={() => setShowFilter((prev) => !prev)}
                />
              </Col>
            </Row>
          </CRUDLayout.HeadSearchSection>

          <CRUDLayout.HeadButtonSection>
            <CreateButton
              onClick={() => history.push('/crm/master/customer/tambah')}
            />
          </CRUDLayout.HeadButtonSection>
        </CRUDLayout.Head>

        <Alert
          show={alert.show}
          showCloseButton={true}
          variant={alert.variant}
          text={alert.text}
          onClose={() => setAlert({ show: false, variant: '', text: '' })}
        />

        {fetching.loading || fetching.error ? (
          <DataStatus
            loading={fetching.loading}
            text={fetching.loading ? 'Memuat data...' : 'Data gagal dimuat'}
          />
        ) : data?.length ? (
          <>
            <div style={{ fontSize: '.9rem' }} className="mt-2 mb-1">
              List Data Customer
            </div>
            <CRUDLayout.Table>
              <THead>
                <Tr>
                  <ThFixed>No</ThFixed>
                  {!isGuest && <ThFixed>Aksi</ThFixed>}
                  <ThFixed>Kode Customer</ThFixed>
                  <Th>Nama Customer</Th>
                  <ThFixed>Jenis Customer</ThFixed>
                  <ThFixed>Kewarganegaraan Customer</ThFixed>
                  <Th>Asal Negara</Th>
                  <Th>Alamat Customer</Th>
                </Tr>
              </THead>
              <TBody>
                {data.map((val, index) => (
                  <Tr key={index}>
                    <TdFixed>
                      {PageNumber(pagination.page, pagination.per_page, index)}
                    </TdFixed>
                    {!isGuest && (
                      <TdFixed>
                        <div className="d-flex justify-content-center align-items-center">
                          <ButtonGroup>
                            <ActionButton
                              size="sm"
                              text={<IoEyeOutline />}
                              onClick={() =>
                                history.push(
                                  `/crm/master/customer/detail/${val.id_customer}`
                                )
                              }
                            />
                            <UpdateButton
                              className="m-0"
                              onClick={() =>
                                history.push(
                                  `/crm/master/customer/ubah/${val.id_customer}`
                                )
                              }
                            />
                            <DeleteButton
                              className="m-0"
                              onClick={() =>
                                setModalDelete({
                                  show: true,
                                  data: val,
                                  loading: false,
                                })
                              }
                            />
                          </ButtonGroup>

                          <Switch
                            id={toString(index + 1)}
                            checked={val.is_hidden === false ? true : false}
                            onChange={() =>
                              changeDataStatus(
                                val.is_hidden,
                                val.id_customer,
                                index
                              )
                            }
                          />
                        </div>
                      </TdFixed>
                    )}
                    <Td>{val.kode_customer}</Td>
                    <Td>{val.nama_customer}</Td>
                    <Td>{val.nama_jenis_customer}</Td>
                    <Td>{val.kewarganegaraan}</Td>
                    <Td>{val.name}</Td>
                    <Td>{val.alamat}</Td>
                  </Tr>
                ))}
              </TBody>
            </CRUDLayout.Table>

            <Pagination
              dataLength={pagination?.per_page}
              dataNumber={
                pagination?.page * pagination?.per_page -
                pagination?.per_page +
                1
              }
              dataPage={
                pagination?.data_count < pagination?.per_page
                  ? pagination?.data_count
                  : pagination?.page * pagination?.per_page
              }
              dataCount={pagination?.data_count}
              currentPage={pagination?.page}
              totalPage={pagination?.total_page}
              onPaginationChange={({ selected }) =>
                setPagination({
                  ...pagination,
                  page: selected + 1,
                })
              }
              onDataLengthChange={(e) =>
                setPagination({
                  ...pagination,
                  page: 1,
                  dataLength: e.target.value,
                })
              }
            />
          </>
        ) : (
          <DataStatus text="Tidak ada data" />
        )}

        <ModalFilterCustomer
          show={showFilter}
          setShow={setShowFilter}
          filter={pagination}
          setFilter={setPagination}
        />

        <ModalDeleteCustomer
          show={modalDelete.show}
          loading={modalDelete.loading}
          data={modalDelete.data}
          onHide={() =>
            setModalDelete({ data: {}, show: false, loading: false })
          }
          onSubmit={deleteCustomer}
        />
      </CRUDLayout>
    </>
  );
};

export default withDropdownCustomerProvider(ListCustomer);
