import { Card, Row, Col } from 'react-bootstrap';
import { InfoItemHorizontal } from 'components';

export const InfoCustomer = ({ data }) => (
  <Card>
    <Card.Body>
      <Row>
        <Col md="6">
          <Row>
            <Col>
              <InfoItemHorizontal
                label="Kode Customer"
                text={data.kode_customer}
              />
              <InfoItemHorizontal
                label="Jenis Customer"
                text={data.nama_jenis_customer}
              />
              <InfoItemHorizontal
                label="Nama Customer"
                text={data.nama_customer}
              />
              <InfoItemHorizontal
                label="Kewarganegaraan Customer"
                text={data.kewarganegaraan}
              />
              <InfoItemHorizontal label="Asal Negara" text={data.name} />
              <InfoItemHorizontal label="Alamat Customer" text={data.alamat} />
            </Col>
          </Row>
        </Col>

        <Col md="6">
          <Row>
            <Col>
              <InfoItemHorizontal label="Telepon" text={`+${data.telepon}`} />
              <InfoItemHorizontal label="Email" text={data.email} />
              <InfoItemHorizontal
                label="Sosial Media"
                text={data.media_sosial}
              />
              <InfoItemHorizontal label="Keterangan" text={data.keterangan} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card.Body>
  </Card>
);
