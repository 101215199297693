import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  id_customer: yup.string().nullable(),
  kode_customer: yup.string().required('Kode customer diperlukan'),
  id_jenis_customer: yup.string().required('Jenis customer diperlukan'),
  nama_customer: yup.string().required('Nama customer diperlukan'),
  alamat: yup.string().required('Alamat diperlukan'),
  kewarganegaraan: yup.string().required('Kewarganegaraan diperlukan'),
  id_negara: yup.string().required('Asal Negara diperlukan'),
  nik: yup.string().nullable().length(16, 'NIK harus 16 karakter'),
  telepon: yup
    .string()
    .required('Nomor telepon diperlukan')
    .min(10, 'Panjang Telepon minimal 10 angka')
    .max(13, 'Panjang Telepon maksimal 13 angka'),
  id_provinsi: yup.string().nullable(),
  id_kabupaten: yup.string().when('id_provinsi', {
    is: (id_provinsi) => !!id_provinsi,
    then: yup.string().required('Kabupaten diperlukan'),
    otherwise: yup.string().nullable(),
  }),
  id_kecamatan: yup.string().when('id_kabupaten', {
    is: (id_kabupaten) => !!id_kabupaten,
    then: yup.string().required('Kecamatan diperlukan'),
    otherwise: yup.string().nullable(),
  }),

  id_desa: yup.string().when('id_kecamatan', {
    is: (id_kecamatan) => !!id_kecamatan,
    then: yup.string().required('Desa diperlukan'),
    otherwise: yup.string().nullable(),
  }),
});
