import { Fragment, useEffect, useState } from 'react';
import { Col, Row, Nav, Tab, Card } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Alert, BackButton, DataStatus } from 'components';
import {
  NavTab,
  TabPane,
  InfoCustomer,
  InfoIdentitasPribadi,
  InfoDukcapil,
  InfoPerbankan,
} from './Components';
import { CustomerApi } from 'api';

const DetailCustomer = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_customer } = useParams();
  const [dataCustomer, setDataCustomer] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [alert, setAlert] = useState({
    text: '',
    variant: '',
  });

  const getInitalData = () => {
    setLoading(true);

    CustomerApi.getSingle({ id_customer })
      .then((res) => setDataCustomer(res.data.data ?? {}))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setNavbarTitle('Customer');
    getInitalData();
  }, []);

  return (
    <Fragment>
      <Alert
        showCloseButton
        show={!!alert.text.length}
        variant={alert.variant}
        text={alert.text}
        onClose={() => setAlert({ text: '', variant: '' })}
      />

      <Row className="d-flex justify-content-between align-items-end mb-1">
        <Col>
          <div style={{ fontSize: '.9rem' }}>Detail Data Customer </div>
        </Col>
        <Col className="d-flex justify-content-end">
          <BackButton onClick={() => history.goBack()} />
        </Col>
      </Row>

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat data . . ." />
      ) : (
        <Fragment>
          <Row>
            <Col>
              <InfoCustomer data={dataCustomer} />
            </Col>
          </Row>

          <div className="mt-4 mb-1" style={{ fontSize: '.9rem' }}>
            Informasi Lainnya (Opsional)
          </div>

          <Card>
            <Tab.Container defaultActiveKey="identitas">
              {/* Tab Navigation */}
              <Card.Header>
                <Nav variant="tabs" defaultActiveKey="identitas">
                  <NavTab eventKey="identitas" title="Identitas Pribadi" />
                  <NavTab eventKey="dukcapil" title="Data Dukcapil" />
                  <NavTab eventKey="perbankan" title="Data Perbankan" />
                </Nav>
              </Card.Header>

              {/* Tab Content */}
              <Card.Body>
                <Tab.Content>
                  <TabPane
                    eventKey="identitas"
                    component={<InfoIdentitasPribadi data={dataCustomer} />}
                  />
                  <TabPane
                    eventKey="dukcapil"
                    component={<InfoDukcapil data={dataCustomer} />}
                  />
                  <TabPane
                    eventKey="perbankan"
                    component={<InfoPerbankan data={dataCustomer} />}
                  />
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Fragment>
      )}
    </Fragment>
  );
};

export default DetailCustomer;
