import { Row, Col } from 'react-bootstrap';
import { InfoItemHorizontal } from 'components';

export const InfoPerbankan = ({ data }) => (
  <Row>
    <Col md="6">
      <InfoItemHorizontal label="Bank" text={data.nama_bank} />
      <InfoItemHorizontal label="No. Rekening" text={data.no_rekening} />
      <InfoItemHorizontal label="Atas Nama" text={data.atas_nama} />
    </Col>
  </Row>
);
