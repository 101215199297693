import { Fragment, useEffect, useState } from 'react';
import { Col, Row, Nav, Tab, Card } from 'react-bootstrap';
import { Formik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { ActionButton, Alert, BackButton, DataStatus } from 'components';
import {
  FormCustomer,
  FormDukcapil,
  FormPerbankan,
  FormIdentitasPribadi,
  NavTab,
  TabPane,
} from './Components';
import { withDropdownCustomerProvider } from './Context';
import {
  formInitialValues,
  validationSchema,
  uploadKTP,
  uploadNPWP,
} from './Utils';
import { CustomerApi } from 'api';

const UbahCustomer = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_customer } = useParams();
  const [dataCustomer, setDataCustomer] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [alert, setAlert] = useState({
    text: '',
    variant: '',
  });

  const onSubmitForm = async (values) => {
    try {
      const foto_ktp = await uploadKTP(values?.fileKtp);
      const foto_npwp = await uploadNPWP(values?.fileNpwp);

      await CustomerApi.update({
        ...values,
        foto_ktp,
        foto_npwp,
      })
        .then(() => history.push('/crm/master/customer'))
        .catch(() =>
          setAlert({
            text: 'Gagal tambah data customer',
            variant: 'danger',
          })
        );
    } catch (err) {
      setAlert({
        text: `Gagal tambah data customer! ${err}`,
        variant: 'danger',
      });
    }
  };

  const getInitalData = () => {
    setLoading(true);

    CustomerApi.getSingle({ id_customer })
      .then((res) => setDataCustomer(res.data.data ?? {}))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setNavbarTitle('Customer');
    getInitalData();
  }, []);

  return (
    <Fragment>
      <Alert
        showCloseButton
        show={!!alert.text.length}
        variant={alert.variant}
        text={alert.text}
        onClose={() => setAlert({ text: '', variant: '' })}
      />

      <Row className="d-flex justify-content-between align-items-end mb-1">
        <Col>
          <div style={{ fontSize: '.9rem' }}>Ubah Data Customer </div>
        </Col>
        <Col className="d-flex justify-content-end">
          <BackButton onClick={() => history.goBack()} />
        </Col>
      </Row>

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat data . . ." />
      ) : (
        <Formik
          enableReinitialize
          initialValues={formInitialValues(dataCustomer)}
          validationSchema={validationSchema}
          onSubmit={onSubmitForm}
        >
          {({ isSubmitting, handleSubmit, errors }) => (
            <Fragment>
              {console.log(errors)}
              <Row>
                <Col>
                  <FormCustomer type="update" />
                </Col>
              </Row>

              <div className="mt-4 mb-1" style={{ fontSize: '.9rem' }}>
                Informasi Lainnya (Opsional)
              </div>

              <Card>
                <Tab.Container defaultActiveKey="identitas">
                  {/* Tab Navigation */}
                  <Card.Header>
                    <Nav variant="tabs" defaultActiveKey="identitas">
                      <NavTab eventKey="identitas" title="Identitas Pribadi" />
                      <NavTab eventKey="dukcapil" title="Data Dukcapil" />
                      <NavTab eventKey="perbankan" title="Data Perbankan" />
                    </Nav>
                  </Card.Header>

                  {/* Tab Content */}
                  <Card.Body>
                    <Tab.Content>
                      <TabPane
                        eventKey="identitas"
                        component={<FormIdentitasPribadi />}
                      />
                      <TabPane
                        eventKey="dukcapil"
                        component={<FormDukcapil />}
                      />
                      <TabPane
                        eventKey="perbankan"
                        component={<FormPerbankan />}
                      />
                    </Tab.Content>
                  </Card.Body>
                </Tab.Container>
              </Card>

              <Row className="mt-3">
                <Col className="d-flex justify-content-end ">
                  <ActionButton
                    className="px-4"
                    text="Simpan"
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  />
                </Col>
              </Row>
            </Fragment>
          )}
        </Formik>
      )}
    </Fragment>
  );
};

export default withDropdownCustomerProvider(UbahCustomer);
