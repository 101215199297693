import { Fragment, useState, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import { IoTrashBinOutline } from 'react-icons/io5';
import ImageViewer from 'react-simple-image-viewer';

const acceptFileType = ['jpg', 'png', 'gif', 'jpeg'];

export const InputImage = ({
  label,
  placeholder,
  onChange,
  onDeleteImage,
  imageLink,
  readOnly = false,
}) => {
  const inputFileRef = useRef();
  const [viewImage, setViewImage] = useState(false);

  const onChangeFileHandler = (e) => {
    const file = e.target.files && e.target.files[0];

    // Check apakah ada file yang dipilih
    if (!file) return null;

    // Ambil file type lalu split dengan '/' dan ambil index ke 1 (cth: image/png menjadi png)
    const getFileType = file.type.split('/')[1];

    // Check jika type file sudah benar
    if (!acceptFileType.includes(getFileType)) {
      e.target.value = '';
      return window.alert(
        `Format file tidak valid, harap masukan file berformat ${acceptFileType.join(
          ', '
        )}`
      );
    }

    const name = file.name;
    const link = URL.createObjectURL(file);

    const finalValue = { data: file, name, link };

    return onChange && onChange(finalValue);
  };

  return (
    <Fragment>
      {!readOnly ? (
        <Form>
          <small>{label}</small>
          <Form.File
            ref={inputFileRef}
            label={placeholder}
            placeholder={placeholder}
            accept="iamge/*"
            className={`form-control form-control-sm`}
            onChange={onChangeFileHandler}
            onClick={(e) => (e.target.value = null)}
            custom
          />
        </Form>
      ) : null}

      {imageLink ? (
        <div
          className="d-flex justify-content-center align-items-center mt-4 "
          style={{ maxWidth: 400, maxHeight: 400, position: 'relative' }}
        >
          <img
            alt="selectedImage"
            className="img-fluid rounded"
            src={imageLink}
            style={{ objectFit: 'cover', cursor: 'pointer' }}
            onClick={() => setViewImage(true)}
          />

          {!readOnly ? <DeleteImage onClick={onDeleteImage} /> : null}
        </div>
      ) : null}

      {viewImage && (
        <div style={{ zIndex: 1000 }}>
          <ImageViewer
            closeOnClickOutside
            disableScroll
            src={[imageLink]}
            currentIndex={0}
            onClose={() => setViewImage(false)}
            backgroundStyle={{ zIndex: 10000 }}
          />
        </div>
      )}
    </Fragment>
  );
};

const DeleteImage = ({ onClick }) => {
  return (
    <Button
      variant="danger"
      size="sm"
      className="m-1"
      style={{
        position: 'absolute',
        top: 0,
        right: 5,
      }}
      onClick={onClick}
    >
      <IoTrashBinOutline />
    </Button>
  );
};
