import { CustomerApi } from 'api';

export const uploadKTP = async (fileKtp) => {
  if (!fileKtp?.data) {
    return fileKtp?.shortLink ?? '';
  }

  let formDataKTP = new FormData();

  formDataKTP.append('file', fileKtp.data);

  return await CustomerApi.uploadKTP(formDataKTP)
    .then((res) => res?.data?.data)
    .catch(() => {
      throw new Error('Gagal upload Foto KTP!');
    });
};

export const uploadNPWP = async (fileNpwp) => {
  if (!fileNpwp?.data) {
    return fileNpwp?.shortLink ?? '';
  }

  let formDataNPWP = new FormData();

  formDataNPWP.append('file', fileNpwp.data);

  return await CustomerApi.uploadNPWP(formDataNPWP)
    .then((res) => res?.data?.data)
    .catch(() => {
      throw new Error('Gagal upload Foto NPWP!');
    });
};
