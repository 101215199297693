/*eslint-disable*/

import {
  IoDocumentTextOutline,
  IoServerOutline,
  IoSpeedometerOutline,
} from 'react-icons/io5';
import Logo from '../assets/image/LogoSadhana.png';
import {
  DetailCustomer,
  JenisCustomer,
  ListCustomer,
  TambahCustomer,
  UbahCustomer,
} from 'pages';

export default {
  LOGO: Logo,

  /* 
    MENU REQUIREMENTS
      > TEXT  = REQUIRED,
      > LINK  = REQUIRED,
      > EXACT = OPTIONAL (TRUE/FALSE),
      > TYPE  = REQUIRED (MENU/DROPDOWN)
      > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
      > LOGO  = OPTIONAL 
  */

  MENU: [
    {
      text: 'Dashboard',
      link: '/',
      type: 'menu',
      exact: true,
      icon: <IoSpeedometerOutline />,
      hak: ['SUPA', 'CRM'],
    },
    {
      text: 'Master Data',
      type: 'dropdown',
      icon: <IoServerOutline />,
      hak: ['SUPA', 'CRM', 'CRM_MAS_JEN', 'CRM_MAS_CUS'],
      menu: [
        {
          text: 'Jenis Customer',
          link: '/crm/master/jenis-customer',
          hak: ['SUPA', 'CRM_MAS_JEN'],
        },
        {
          text: 'Customer',
          link: '/crm/master/customer',
          hak: ['SUPA', 'CRM_MAS_CUS'],
        },
      ],
    },
    {
      text: 'Laporan',
      type: 'dropdown',
      icon: <IoDocumentTextOutline />,
      hak: ['SUPA', 'CRM'],
      menu: [],
    },
  ],

  ROUTES: [
    {
      exact: true,
      path: '/crm/master/jenis-customer',
      page: JenisCustomer,
      hak: ['SUPA', 'CRM_MAS_JEN'],
    },
    {
      exact: true,
      path: '/crm/master/customer',
      page: ListCustomer,
      hak: ['SUPA', 'CRM_MAS_CUS'],
    },
    {
      exact: true,
      path: '/crm/master/customer/tambah',
      page: TambahCustomer,
      hak: ['SUPA', 'CRM_MAS_CUS'],
    },
    {
      exact: true,
      path: '/crm/master/customer/ubah/:id_customer',
      page: UbahCustomer,
      hak: ['SUPA', 'CRM_MAS_CUS'],
    },
    {
      exact: true,
      path: '/crm/master/customer/detail/:id_customer',
      page: DetailCustomer,
      hak: ['SUPA', 'CRM_MAS_CUS'],
    },
  ],
};
